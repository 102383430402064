import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

function Seo({title, children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            irkutsk
          }
        }
      }
    `
  )

  const metaDescription = "Проститутки Иркутска расслабят Вас после трудовых будней в уютной атмосфере любви и нежности. Индивидуалки Иркутска готовы ко встрече в приватной обстановке." || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title

  return (
    <>
      <link rel="canonical" href="https://vipdosug.org/prostitutki-irkutska/"/>
      <link rel="alternate" href="https://vipdosug.org/prostitutki-irkutska/" hreflang="ru"/>
      <link rel="amphtml" href="https://vipdosug.org/prostitutki-irkutska/amp/"/>
      <link rel="apple-touch-icon" href="/apple-touch-icon.png"/>
	    <link rel="icon" href="/favicon.svg" type="image/svg+xml"/>
	    <link rel="icon" href="/favicon.ico" sizes="32x32"/>
      <title>{defaultTitle ? `${title} - ${defaultTitle}` : title}</title>
      <meta property="og:locale" content="ru_RU"/>
      <meta property="og:site_name" content="vipdosug.org"/>
      <meta property="og:description" content={metaDescription}/>
      <meta property="og:image" content={site.siteMetadata.irkutsk}/>
      <meta property="og:url" content="https://vipdosug.org/prostitutki-irkutska/"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:image" content={site.siteMetadata.irkutsk}/>
      <meta name="twitter:description" content={metaDescription}/>
      <meta name="twitter:title" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
      <meta name="twitter:creator" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:site" content={site.siteMetadata?.author || ``}/>
      <meta name="twitter:card" content="summary_large_image"/>
      <meta itemprop="image" content={site.siteMetadata.irkutsk}/>
	    <meta itemprop="description" content={metaDescription}/>
	    <meta itemprop="name" content={defaultTitle ? `${title} - ${defaultTitle}` : title}/>
	    <meta name="description" content={metaDescription}/>
      <link rel="alternate" type="application/atom+xml" href="https://vipdosug.org/vipdosug.atom" title="Проститутки России, лучшие индивидуалки | VipDosug.org"/>
      <script type="application/ld+json">
  {`
  {
    "@context": "http://schema.org",
    "@type": "WebPage",
    "url": "https://vipdosug.org/",
    "name": "VipDosug.Org",
    "sameAs": [
        "https://www.youtube.com/@dosugcz8671",
        "https://flipboard.com/@dosug",
        "https://vk.com/dosug.cz_ru",
        "https://t.me/DosugOfficialle",
        "https://twitter.com/DosugczOfficial",
        "https://www.facebook.com/dosugru.net",
        "https://ru.pinterest.com/dosugru/"
    ]
}
  `}
</script>
<script type="application/ld+json">
  {`
  {
    "@context": "https://schema.org",
    "author": {
        "@type": "WebPage",
        "name": "VipDosug.Org",
        "url": "https://vipdosug.org/"
    },
    "@type": "Article",
    "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": "https://vipdosug.org/prostitutki-irkutska/"
    },
    "headline": "Проститутки Иркутска, лучшие индивидуалки | VipDosug.org",
    "description": "Проститутки Иркутска расслабят Вас после трудовых будней в уютной атмосфере любви и нежности. Индивидуалки Иркутска готовы ко встрече в приватной обстановке.",
    "articleBody": "Чем вам известен город Иркутск? Да, здесь сильные морозы и именно здесь был арестован лидер белого движения Колчак. Ну, помимо этих фактов, еще что это очень красивый город в котором можно просто отлично провести время. Здесь можно даже воплотить в жизнь самые откровенные эротические фантазии. Потому что индивидуалки города Иркутска уже истосковались по мужчинам, которые бы хотели отлично провести свое время. В перечне интимных услуг проституток Иркутска вы можете найти то, что вам идеально подходит, то, чего бы вы давно и сильно хотели. Экзотического анального секса или обычного вагинального? Может быть вы давно хотели увидеть, как женщина танцует для вас приватный танец или стриптиз. Может хотели почувствовать ее нежные губки на своем достоинстве. Теперь вы можете себе это позволить ведь проститутки Иркутска ждут вашего звонка. Хватит мечтать о сексе - пора им заняться! Звоните по номеру ... и вскоре ваши самые сокровенные мысли станут фантастической реальностью!",
    "image": [
        "https://vipdosug.org/images/og-irkutsk.png"
    ],
   
    "articleSection": "Развлечения",
    "publisher": {
        "@type": "Organization",
        "name": "VipDosug.Org",
        "url": "https://vipdosug.org/",
        "logo": {
            "@type": "ImageObject",
            "url": "https://vipdosug.org/images/amp.png",
            "width": 264,
            "height": 59
        }
    }
}
  `}
</script>
      {children}
    </>
  )
}

export default Seo
